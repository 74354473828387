import React, { useEffect, useRef, useState } from "react";
import {
    saveDeliveryoffersApi,
    searchProductApi,
} from "../../Apis/DeliveryCharges";
import { liveURL } from "../../config/config";
import { toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
import AlertDialogSlide from "../DeliverySlot/SlotPopup";

export default function DeliveryOfferCard({ item, index, disableButtons }) {
    const [offerCartAmount, setOfferCartAmount] = useState(
        item._id.offerCartAmount
    );
    const [offerPrice, setOfferPrice] = useState(item._id.offerPrice);
    const [pincodes, setPincodes] = useState(item.pincodes);
    const [productId, setProductId] = useState(item._id.productId);

    const handleSave = async () => {
        const data = {
            productId: productId,
            offerCartAmount: offerCartAmount,
            offerPrice: offerPrice,
            pincodes: pincodes,
        };
        try {
            await saveDeliveryoffersApi(data);
            toast.success("Offer saved successfully");
        } catch (err) {
            console.error("Error saving delivery charges:", err);
            toast.error("Error saving delivery charges");
        }
    };

    return (
        <div
            style={{
                backgroundColor: "#f0f0f0",
                padding: 10,
                borderRadius: 10,
                alignItems: "center",
                display: "flex",
                gap: 20,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 20,
                }}
            >
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    <p
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Product Id
                    </p>
                    <input
                        type="text"
                        value={productId}
                        onChange={(e) => {
                            setProductId(e.target.value);
                        }}
                        placeholder="Product Id"
                        style={{
                            display: "flex",
                            flex: 1,
                            textTransform: "capitalize",
                            backgroundColor: "white",
                            padding: 10,
                        }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    <p
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Cart Value
                    </p>
                    <input
                        onChange={(e) => {
                            setOfferCartAmount(e.target.value);
                        }}
                        defaultValue={item._id.offerCartAmount}
                        type="text"
                        placeholder="Minimum Order value"
                        style={{
                            border: "none",
                            backgroundColor: "white",
                            padding: 10,
                            borderRadius: 8,
                        }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    <p
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Offer Price
                    </p>
                    <input
                        onChange={(e) => {
                            setOfferPrice(e.target.value);
                        }}
                        type="text"
                        defaultValue={item._id.offerPrice}
                        placeholder="Offer Price"
                        style={{
                            border: "none",
                            backgroundColor: "white",
                            padding: 10,
                            borderRadius: 8,
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    backgroundColor: "red",
                }}
            >
                <div
                    style={{
                        backgroundColor: "white",
                        flex: 1,
                        height: "100%",
                        display: "flex",
                    }}
                >
                    {pincodes.map((item, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 5,
                                }}
                            >
                                <p>{item}</p>
                                <button
                                    onClick={() => {}}
                                    style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        padding: 5,
                                        borderRadius: 5,
                                        border: "none",
                                    }}
                                >
                                    X
                                </button>
                            </div>
                        );
                    })}
                </div>
                <div
                    style={{
                        flex: 0.2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <button
                        disabled={disableButtons}
                        onClick={handleSave}
                        style={{
                            backgroundColor: "#0d9e67",
                            padding: "10px 30px",
                            fontSize: 14,
                            color: "#fff",
                            width: "fit-content",
                            borderRadius: 10,
                            border: "none",
                            maxHeight: 40,
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}
