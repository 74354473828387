import React, { useEffect } from "react"; 
import { toast } from "react-toastify";  
import "react-toastify/dist/ReactToastify.css"; 
import "./Login3412.css";
import { baseURL } from "../../config/config";
import { CircularProgress } from "@mui/material";
import { getDataSec, postDataSec } from "../../Apis/fun";
import { formatIndian } from "../../utils/toast"; 

function FloatingAdjust() {
  const [userId, setUserId] = React.useState("");
  const [password, setPassword] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [user,setUser]=React.useState()
  const [otpSent, setOtpSent] = React.useState(false);

  useEffect(()=>{
      if(userId.length===10){
        setOtpSent(false)
        handleSignIn()
      }
  },[userId])

  const handleCollectCash = async (deliveryPartnerId) => {
    try { 
        const result = await postDataSec(
            `${baseURL}deliveryBoy/collectCash`,
            {
                amount:password,
                deliveryPartnerId,
            }
        );
        if (result.success) {
            // setDeliveryBoyList([]);
            toast.success(result.message);
            window.location.reload();
        } else {
            toast.error(result.message);
        }
    } catch (err) {
        console.log(err);
        toast.error("ERROR IN COLLECTING CASH");
    }
};

  const handleSignIn = async (e) => {
    if (e)
    e.preventDefault();
    try {
      setLoading(true);
      if (!otpSent) {
        getDataSec(`${baseURL}deliveryBoy?phoneNo=${userId}`)
        .then((result) => {
            setOtpSent(true)
            setLoading(false)
            if (result.success) {
                setUser(result.details); 
            } else {
                toast.error(result.message);
            }
        })
        .catch((err) => {
            setLoading(false)
            console.log(err);
        });
      } else {
        handleCollectCash(user._id) 
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error("Something went wrong, Please try again later"); // Display error toast message
    }
  };

  return (
    <div
      style={{
        justifyContent: "center",  
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        alignItems: "center", 
        flexDirection: "column",
        display: "flex", 
        height:'80vh',
        width:'100%',
      }}
    > 
    <h1  style={{ textShadow: "0px 0px 20px 10px #aaa" }} >Floating Cash Adjustment</h1>
      <form className="form" style={{ boxShadow: "0px 0px 20px 10px #aaa" }}>
       {!otpSent && <>
        <div className="flex-column">
          <label>Phone Number </label>
        </div>
        <div className="inputForm">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            id="phone_number"
            data-name="phone number"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <rect
                id="Rectangle_5"
                data-name="Rectangle 5"
                width="24"
                height="24"
                fill="none"
              ></rect>{" "}
              <path
                id="Shape"
                d="M7.02,15.976,5.746,13.381a.7.7,0,0,0-.579-.407l-1.032-.056a.662.662,0,0,1-.579-.437,9.327,9.327,0,0,1,0-6.5.662.662,0,0,1,.579-.437l1.032-.109a.7.7,0,0,0,.589-.394L7.03,2.446l.331-.662a.708.708,0,0,0,.07-.308.692.692,0,0,0-.179-.467A3,3,0,0,0,4.693.017l-.235.03L4.336.063A1.556,1.556,0,0,0,4.17.089l-.162.04C1.857.679.165,4.207,0,8.585V9.83c.165,4.372,1.857,7.9,4,8.483l.162.04a1.556,1.556,0,0,0,.165.026l.122.017.235.03a3,3,0,0,0,2.558-.993.692.692,0,0,0,.179-.467.708.708,0,0,0-.07-.308Z"
                transform="translate(4.393 6.587) rotate(-30)"
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                stroke-width="1.5"
              ></path>{" "}
            </g>
          </svg>
          <input
            type="text" 
            className="input"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off" 
            spellCheck="false" 
            placeholder="Enter Captain's Phone Number"
            value={userId}
            onChange={(e) => setUserId(e.target.value)} // Update userId state on change
          />
        </div></>}
        {otpSent && (
          <>
            <div className="flex-column">
              <label>Captain Name </label>
              <label style={{textTransform:'uppercase'}} >{ `${user?.name} `} </label> 
            </div>
            <div className="flex-column"> 
              <label> { `Floating Cash  ₹ ${formatIndian( user?.floatingCash)}`}  </label>
            </div>
            <div className="inputForm">
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 4H10.5M10.5 4C12.9853 4 15 6.01472 15 8.5C15 10.9853 12.9853 13 10.5 13H6L13 20M10.5 4H18M6 8.5H18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              <input
                type="text" 
                className="input"
                placeholder="Enter Amount"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </>
        )}
        <div style={{display:'flex',flexDirection:'row',gap:20}} >
       {otpSent&& <button
          style={{ backgroundColor: loading ? "#eee" : "#000" }}
          className="button-submit"
          onClick={(e) => handleSignIn(e)}
        >
          {loading ? (
            <CircularProgress style={{ color: "black" }} color="inherit" size={24} />
          ) : otpSent ? (
            "Collect Cash"
          ) : (
            "Fetch Details"
          )}
        </button>  }{otpSent && <button
          style={{  
            backgroundColor:" #fff",
            border: "none",
            color: "#151717",
            fontSize: "15px",
            fontWeight: 500,
            borderRadius: "10px",
            border:"1px solid #151717", 
            height: "50px",
            width: "100%",
            cursor: "pointer",}}
          className="button-submit"
          onClick={(e) =>{
            window.location.reload()
          }}
        >
          Change Captain
        </button>}</div>
      </form>
    </div>
  );
}

export default FloatingAdjust;