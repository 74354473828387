import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen, children, heading }) {
    return (
        <React.Fragment>
            <Dialog
                fullWidth
                sx={{ borderRadius: 10 }}
                open={open}
                TransitionComponent={Transition}
                onClose={() => {
                    setOpen(false);
                }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{heading}</DialogTitle>
                <DialogContent>{children}</DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
