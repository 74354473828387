import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { postDataSec } from "../../Apis/fun";
import { baseURL } from "../../config/config";

const BonusModal = ({ setShowBonusModal, activeBonus, type }) => {
    const [startTime, setStartTime] = useState(
        activeBonus?.startTime
            ? formatDateToDatetimeLocal(activeBonus.startTime)
            : ""
    );
    const [endTime, setEndTime] = useState(
        activeBonus?.endTime
            ? formatDateToDatetimeLocal(activeBonus.endTime)
            : ""
    );
    const [title, setTitle] = useState(activeBonus?.title ?? "");
    const [amount, setAmount] = useState(activeBonus?.amount ?? "");

    useEffect(() => {
        console.log(startTime, endTime, title, amount);
    }, [startTime, endTime, title, amount]);

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "scroll";
        };
    });

    const activateBonus = async () => {
        try {
            let result;
            if (activeBonus?._id) {
                result = await postDataSec(`${baseURL}updateBonus`, {
                    title,
                    startTime,
                    endTime,
                    amount,
                    _id: activeBonus._id,
                    type,
                });
            } else {
                result = await postDataSec(`${baseURL}setBonus`, {
                    title,
                    endTime,
                    startTime,
                    amount,
                    type,
                });
            }
            if (result.success) {
                toast.success(result.message);
                setShowBonusModal(false);
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            console.log(error);
            toast.error("Cannot activate bonus");
        }
    };

    return (
        <div
            onClick={() => setShowBonusModal(false)}
            style={{
                position: "absolute",
                display: "flex",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.3)",
                top: 0,
                left: 0,
                zIndex: 1000000,
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
            }}
        >
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                style={{
                    width: 400,
                    height: 300,
                    backgroundColor: "white",
                    borderRadius: 12,
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    padding: 10,
                }}
            >
                <label>Title</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                />
                <label>Amount</label>
                <input
                    type="number"
                    value={amount}
                    onChange={(e) => {
                        setAmount(e.target.valueAsNumber);
                    }}
                />
                <label>Enter Start Date</label>
                <input
                    type="datetime-local"
                    value={startTime}
                    onChange={(e) => {
                        setStartTime(e.target.value);
                    }}
                />
                <label>Enter End Date</label>
                <input
                    type="datetime-local"
                    value={endTime}
                    onChange={(e) => {
                        setEndTime(e.target.value);
                    }}
                />
                {activeBonus?._id ? (
                    <button onClick={activateBonus}>Update</button>
                ) : (
                    <button onClick={activateBonus}>Activate</button>
                )}
            </div>
        </div>
    );
};

function formatDateToDatetimeLocal(temp) {
    if (!temp) {
        return;
    }
    const date = new Date(temp);
    const pad = (num) => String(num).padStart(2, "0");
    return (
        [
            date.getFullYear(),
            pad(date.getMonth() + 1),
            pad(date.getDate()),
        ].join("-") +
        "T" +
        [pad(date.getHours()), pad(date.getMinutes())].join(":")
    );
}

export default BonusModal;
