import React, { useEffect, useState } from "react";
import "./OrderDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import { orderDetailsApi } from "../../Apis/orders";
import { OrderDetailsProductCard } from "./OrderProductCard";
import BackSvg from "../../assets/back.svg";
import { printInvoice } from "../../utils/toast";
import {
    cancelOrder,
    confirmPendingOrder,
    deliverOrderApi,
    dispatchProcessedOrder,
    getOrders,
    processConfirmedOrder,
    restoreOrderApi,
} from "../../Apis/orders";
import AlertDialogSlide from "../DeliverySlot/SlotPopup";
import { EditOrderDetailsProductCard } from "./EditProduct";
import { searchProductApi } from "../../Apis/DeliveryCharges";
import { baseURL, headers, liveURL } from "../../config/config";
import { fetchProductById } from "../../Apis/Products";
import { toast } from "react-toastify";
import axios from "axios";
import { min } from "moment";
import SingleOrderCard from "../jobs/JobCard";
import { getDataSec, postDataSec } from "../../Apis/fun";
import { Button } from "@mui/material";

const OrderDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [orderData, setOrderData] = useState(null);
    const [jobData, setJobData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const edit = queryParams.get("edit");

    const [isEditable, setIsEditable] = useState(edit || false);
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchedData, setSearchedData] = useState([]);
    const [product, setProduct] = useState({});
    const [showChange, setShowChange] = useState(false);
    const [newTotal, setNewTotal] = useState(0);
    const [newSubTotal, setNewSubTotal] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [newDiscountAmount, setNewDiscountAmount] = useState(0);

    const id = queryParams.get("id");
    const refreshPage = () => {
        window.location.reload();
    };

    const acceptPendingOrder = async (id) => {
        await confirmPendingOrder(id);
        refreshPage();
    };

    const processOrder = async (id) => {
        await processConfirmedOrder(id);
        refreshPage();
    };

    const dispatchOrder = async (id) => {
        await dispatchProcessedOrder(id);
        refreshPage();
    };

    const restoreOrder = async (id) => {
        await restoreOrderApi(id);
        refreshPage();
    };
    const deliverOrder = async (id) => {
        await deliverOrderApi(id, otp);
        refreshPage();
    };

    const cancelorder = async (id) => {
        await cancelOrder(id);
        refreshPage();
    };

    const handleBackBtn = () => {
        navigate(-1);
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
    }

    const printOrder = async (id) => {
        const temp = orderData;
        const data = {
            invoice: {
                seller: {
                    phoneNo: temp.seller.phoneNo,
                    sellerInformation: {
                        name: temp.seller.sellerInformation.name,
                        fullAddress: temp.seller.sellerInformation.fullAddress,
                    },
                },
                deliveryCharge: temp.deliveryCharge,
                amount: temp.amount,
                orderAddress: {
                    name: temp.address.name,
                    line1: temp.address.line1,
                    line2: temp.address.line2,
                    fullAddress: temp.address.fullAddress,
                    mobileNo: temp.address.mobileNo,
                },
                customerMessage: temp.customerMessage,
                deliveryDate: formatDate(temp.deliveryDate),
                deliveryTime: {
                    slot: temp.deliveryTime.slot,
                },
                paymentMode: temp.paymentMode,
                id: temp.id,
                date: temp ? formatDate(temp.date) : "",
                invoiceId: `1-${temp.id}`,
            },
        };
        let tempArray = [];
        temp.products.forEach((element) => {
            tempArray.push({
                hsnCode: element.hsnCode,
                recommendedAttribute: element.recommendedAttribute,
                price: element.price,
                sellPrice: element.sellPrice,
                quantity: element.quantity,
                name: element.name,
            });
        });
        if (orderData?.deliveryCharge > 0) {
            tempArray.push({
                hsnCode: 10030543,
                recommendedAttribute: 1,
                sellPrice: orderData?.deliveryCharge,
                quantity: 1,
                name: "Delivery Charge",
            });
        }
        if (orderData?.promocode !== null) {
            tempArray.push({
                hsnCode: 10031218,
                recommendedAttribute: 1,
                sellPrice: -orderData.couponDiscount,
                quantity: 1,
                name: `PromoCode Discount`,
            });
        }
        data.invoice.products = tempArray;
        printInvoice(data);
    };

    const editOrder = (id) => {
        setIsEditable(true);
    };

    const getData = async () => {
        if (!loading) {
            setLoading(true);
            const result = await orderDetailsApi(id);
            if (result.success) {
                if (result.areaId === null || result.areaId === undefined) {
                    const arearesult = await axios.get(
                        `https://api.aapkabazar.co/api/searchcity?area=${result.order.address.fullAddress}`
                    );
                    if (arearesult.data.success) {
                        result.order.areaId = arearesult.data.data.areaId;
                        result.order.cityId = arearesult.data.data._id;
                    }
                }
                setOrderData({ ...result.order });
                setProducts([...result.order.products]);
            } else {
                console.log(result.message);
            }
            setLoading(false);
        }
    };

    const getJobData = async () => {
        try {
            const result = await getDataSec(
                `${baseURL}jobs/details?orderNo=${id}`
            );
            if (result.success) {
                setJobData(result.job);
            }
        } catch (error) {
            console.log(error);
            toast.error("Error");
        }
    };
    useEffect(() => {
        getData();
        getJobData();
    }, []);
    function convertTimeToIST(zuluTime) {
        return new Date(zuluTime).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
        });
    }
    const renderBtn = (status, id) => {
        switch (status) {
            case "pending":
                return (
                    <>
                        <button onClick={() => editOrder(id)}>
                            Edit Order
                        </button>
                        <button onClick={() => acceptPendingOrder(id)}>
                            Accept Order
                        </button>
                        <button onClick={() => cancelorder(id)}>
                            Cancel order
                        </button>
                    </>
                );
            case "confirmed":
                return (
                    <>
                        <button onClick={() => editOrder(id)}>
                            Edit Order
                        </button>
                        <button onClick={() => processOrder(id)}>
                            Process Order
                        </button>
                        <button onClick={() => cancelorder(id)}>
                            Cancel order
                        </button>
                    </>
                );
            case "processed":
                return (
                    <>
                        {/* <button onClick={() => dispatchOrder(id)}>
                            Dispatch Order
                        </button> */}
                        <button onClick={() => cancelorder(id)}>
                            Cancel order
                        </button>
                    </>
                );
            case "dispatched":
                return (
                    <>
                        <p>otp : {orderData.otp}</p>
                        {/* <input style={{ backgroundColor: "white", boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.126)', border: 0, padding: '0 20px' }} placeholder="Enter Otp" onChange={(e) => setOtp(e.target.value)} /> */}
                        {/* <button onClick={() => deliverOrder(id)}>
                            Order Deliver
                        </button> */}
                        <button onClick={() => cancelorder(id)}>
                            Order cancel
                        </button>
                    </>
                );
            case "cancelled":
                return (
                    <>
                        {orderData?.userCancelStatus ? null : (
                            <button onClick={() => restoreOrder(id)}>
                                Restore
                            </button>
                        )}
                    </>
                );
            case "delivered":
                return <></>;
            default:
                return null;
        }
    };

    const handleAddProduct = async (item) => {
        try {
            const result = await fetchProductById(item._id);
            const newProduct = {
                quantity: 1,
                name: result.product.name,
                price: result.product.price,
                minQuantity: result.product.storeMinQuantity,
                minSellPrice: result.product.minSellPrice,
                newlyAdded: true,
                sellPrice: result.product.sellPrice,
                hsnCode: result.product.hsnCode,
                id: result.product.id,
                images: result.product.images,
                recommendedAttribute: result.product.recommendedAttribute,
                sku: result.product.sku,
                categoryId: result.product.categoryId,
                sellerProductId: result.product._id,
                productId: result.product.productId,
            };
            // console.log(result);
            setProducts((prev) => [...prev, newProduct]);
            setModalOpen(false);
        } catch (err) {
            console.log(err);
            toast.error("Error fetching product Details");
        }

        // setProducts(prev => ({ ...prev, newProduct }))
    };
    React.useEffect(() => {
        if (searchQuery) {
            searchKeywordHandler(searchQuery);
        }
    }, [searchQuery]);

    const searchKeywordHandler = async (e) => {
        try {
            if (e.length >= 2) {
                const response = await searchProductApi(e);
                setSearchedData(response.products);
            }
        } catch (err) {
            console.error("Error searching product:", err);
            throw err;
        }
    };
    function convertToInteger(currencyStr) {
        // Remove any non-numeric characters using a regular expression
        let numericStr = currencyStr.replace(/[^\d]/g, "");
        // Convert the resulting string to an integer
        let result = parseInt(numericStr, 10);
        // Check if the conversion resulted in NaN, and return 0 in that case (or any default value you prefer)
        if (isNaN(result)) {
            return 0;
        }
        return result;
    }

    const updateOrder = async () => {
        let tempproducts = [],
            outOfStockProds = [];
        products.forEach((item) => {
            if (item.quantity > 0) {
                tempproducts.push({
                    ...item,
                    productId: item.productId || item._id,
                    sellPrice: !item.newlyAdded
                        ? item.sellPrice
                        : item.quantity > item.minQuantity
                        ? item.sellPrice
                        : item.minSellPrice,
                });
            } else {
                outOfStockProds.push(item);
            }
        });
        if (tempproducts.length === 0) {
            toast.error("Please add atleast one product to update the order");
            return;
        }
        const data = {
            orderID: orderData?.id,
            areaId: orderData?.areaId,
            locationId: orderData?.cityId,
            products: tempproducts,
            outOfStockProducts: outOfStockProds,
            offerId: orderData?.offerId,
            promocode: orderData?.promocode,
            deliveryCharges: deliveryCharge,
            amount: newTotal,
            status: orderData?.status,
            isDeliveryCharge: deliveryCharge === 0 ? false : true,
            couponDiscount: newDiscountAmount,
        };

        try {
            const result = await axios.post(
                `${baseURL}order/update`,
                data,
                headers
            );
            if (result.data.success) {
                toast.success("Order updated successfully");
                refreshPage();
            } else {
                throw new Error("Invalid response from the API");
            }
        } catch (err) {
            console.error("Error modifying order:", err);
            throw err;
        }
    };

    const calculateNewPrice = async () => {
        try {
            let data = {
                areaId: orderData?.areaId,
                locationId: orderData?.cityId,
                products: products
                    .filter((item) => item.sellPrice > 1) // Filter out items with sellPrice
                    .map((item) => ({
                        productId: item?.productId || item._id,
                        quantity: item.quantity,
                    })),
            };

            if (orderData?.offerId && orderData?.offerId !== null) {
                data.offerId = orderData?.offerId;
            }

            const result = await axios.post(
                "https://api.aapkabazar.co/api/cart/calculatePrice",
                data
            );

            if (
                result.data.cartResult &&
                typeof result.data.cartResult.payableAmount === "number"
            ) {
                setNewTotal(result.data.cartResult.payableAmount);
                if (result.data.cartResult.isDeliveryCharges === false) {
                    setDeliveryCharge(0);
                } else {
                    setDeliveryCharge(
                        convertToInteger(result.data.cartResult.deliveryCharges)
                    );
                }
                if (result.data.cartResult.isPromocodeApplied) {
                    setNewDiscountAmount(
                        result.data.cartResult.couponDiscountAmount
                    );
                }
            } else {
                // throw new Error("Invalid response from the API");
            }
        } catch (err) {
            console.error("Error modifying order:", err);
            throw err;
        }
    };

    useEffect(() => {
        console.log("products", products[0]?.quantity);
        console.log("orderdata", orderData?.products[0]?.quantity);
        calculateNewPrice();
    }, [products]);

    const createJob = async (id) => {
        try {
            const result = await postDataSec(`${baseURL}job/create`, {
                orderId: id,
            });
            if (result) {
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error("error");
            console.log(error);
        }
    };

    if (!orderData) {
        return <></>;
    }

    return (
        <div id="orderDetailsRootContainer">
            <AlertDialogSlide open={modalOpen} setOpen={setModalOpen}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        minWidth: 600,
                        minHeight: 300,
                    }}
                >
                    <h3 style={{ margin: 0 }}>Add Product</h3>
                    <input
                        type="text"
                        style={{ width: "calc(100% - 10px)", marginRight: 10 }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr className="headerRow">
                                <th>Sr No</th>
                                <th>Image</th>
                                <th>HSN Code</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedData.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ width: "10%" }}>
                                        <span>{index + 1}</span>
                                    </td>
                                    <td style={{ width: "20%" }}>
                                        <img
                                            src={`${liveURL}/public/product/${item.id}/${item.images[0]}`}
                                            style={{ width: 50, height: 50 }}
                                            alt=""
                                        />
                                    </td>
                                    <td style={{ width: "20%" }}>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.hsnCode}
                                        </span>
                                    </td>
                                    <td style={{ width: "70%" }}>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.name}
                                        </span>
                                    </td>
                                    <td style={{ padding: 10 }}>
                                        <button
                                            className="SaveBtn"
                                            onClick={() =>
                                                handleAddProduct(item)
                                            }
                                        >
                                            Add
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </AlertDialogSlide>

            <AlertDialogSlide open={showChange} setOpen={setShowChange}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                    }}
                >
                    <h3 style={{ margin: 0 }}>Change Order</h3>
                    <table className="updateOrderTable">
                        <thead>
                            <tr>
                                <th>Particulars</th>
                                <th>Old Amount</th>
                                <th>New Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Delivery Charge</td>
                                <td>{orderData?.deliveryCharge}</td>
                                <td> {deliveryCharge}</td>
                            </tr>
                            {orderData?.offerId &&
                            orderData?.offerId !== null ? (
                                <tr>
                                    <td>Discount Amount </td>
                                    <td>-{orderData?.couponDiscount}</td>
                                    <td>-{newDiscountAmount}</td>
                                </tr>
                            ) : (
                                <></>
                            )}
                            <tr>
                                <td>Grand total</td>
                                <td>{orderData?.amount}</td>
                                <td>{newTotal}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ display: "flex", flex: 1, gap: 10 }}>
                        <button
                            style={{
                                display: "flex",
                                flex: 1,
                                padding: "10px",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={() => setShowChange(false)}
                        >
                            Cancel
                        </button>
                        <button
                            style={{
                                display: "flex",
                                flex: 1,
                                padding: "10px",
                                backgroundColor: "rgb(46, 240, 111)",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={() => {
                                updateOrder();
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </AlertDialogSlide>

            <div id="topBtnsDiv">
                <button id="backBtn" onClick={handleBackBtn}>
                    <img src={BackSvg} style={{ width: 20, height: 20 }} />
                    Back
                </button>
                <div style={{ display: "flex", gap: 20 }}>
                    {renderBtn(orderData?.status, orderData?._id)}
                    <button id="DownloadOrderBtn" onClick={() => printOrder()}>
                        Print Order
                    </button>
                </div>
            </div>

            <div id="orderDetailsContainer">
                <div id="sellerDetails">
                    <h2>Seller Details</h2>
                    <div className="line"></div>
                    <h4>Aap ka Bazar</h4>
                    <span>
                        {orderData?.seller.sellerInformation.fullAddress}
                    </span>
                    <h4>
                        Landmark:{" "}
                        <span>
                            {orderData?.seller.sellerInformation.landmark}
                        </span>
                    </h4>
                    <span>{orderData?.seller.phoneNo}</span>
                    {orderData?.customerMessage ? (
                        <span
                            style={{
                                marginTop: 40,
                                backgroundColor: "#ff00002e",
                                border: "1px solid red",
                                padding: "5px",
                                borderRadius: 8,
                            }}
                        >
                            {" "}
                            Customer Message : {orderData.customerMessage}
                        </span>
                    ) : null}
                </div>
                <div id="orderDetails">
                    <h2>Order Details</h2>
                    <div className="line"></div>
                    <h4>{orderData?.address.name}</h4>
                    <span>{orderData?.address.line1}</span>
                    <span>{orderData?.address.line2}</span>
                    <span>{orderData?.address.mobileNo}</span>
                    <div className="line"></div>
                    <h4>
                        Order ID: <span>{orderData?.id}</span>
                    </h4>
                    <h4>
                        {orderData.paymentSource &&
                            Object.keys(orderData.paymentSource).map((mode) => {
                                if (orderData.paymentSource[mode] === 0)
                                    return <></>;
                                return (
                                    <p>
                                        {mode} :
                                        <span>
                                            {" "}
                                            {JSON.stringify(
                                                orderData.paymentSource[mode]
                                            )}
                                        </span>
                                    </p>
                                );
                            })}
                    </h4>
                    <h4>
                        Order Status: <span>{orderData?.status}</span>
                    </h4>
                </div>
            </div>

            <div id="dateDiv">
                <h4>
                    Order Date: <span>{convertTimeToIST(orderData?.date)}</span>
                </h4>
                <h4>
                    Delivery Date:{" "}
                    <span>{convertTimeToIST(orderData?.deliveryDate)}</span>
                </h4>
                <h4>
                    Delivery Slot: <span>{orderData?.deliveryTime.slot}</span>
                </h4>
            </div>

            {orderData.status !== "confirmed" &&
            orderData.status !== "pending" ? (
                jobData ? (
                    <div
                        style={{
                            margin: "0 20px",
                            backgroundColor: "transparent",
                            borderRadius: 8,
                        }}
                    >
                        <SingleOrderCard item={jobData} />
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                        }}
                    >
                        <p>Job not created</p>
                        <Button
                            onClick={() => {
                                createJob(orderData._id);
                            }}
                        >
                            Create Job
                        </Button>
                    </div>
                )
            ) : (
                <></>
            )}

            <div id="productList">
                {isEditable ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 20,
                        }}
                    >
                        <button
                            onClick={() => setModalOpen(true)}
                            style={{
                                padding: "10px 20px",
                                borderRadius: 8,
                                borderColor: "rgb(255 254 216)",
                                boxShadow: "none",
                                backgroundColor: "#f9f984",
                            }}
                        >
                            Add Product
                        </button>
                        <button
                            onClick={() => setShowChange(true)}
                            style={{
                                padding: "10px 20px",
                                borderRadius: 8,
                                borderColor: "#51ff8c",
                                boxShadow: "none",
                                backgroundColor: "#9fff9a",
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            onClick={() => {
                                refreshPage();
                            }}
                            style={{
                                padding: "10px 20px",
                                borderRadius: 8,
                                borderColor: "rgb(253, 253, 239)",
                                boxShadow: "none",
                                backgroundColor: "rgb(253, 253, 239)",
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                ) : null}
                <table>
                    <thead className="headerRow">
                        <tr>
                            <th>Sr</th>
                            <th>Item/SKU Code</th>
                            <th>Product Name</th>
                            <th>MRP(₹)</th>
                            <th>Sell Price(₹)</th>
                            <th>Total Qty</th>
                            <th>Total</th>
                            {isEditable ? <th>Action</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {products?.map((item, index) => (
                            <EditOrderDetailsProductCard
                                index={index}
                                item={item}
                                isEditable={isEditable}
                                products={products}
                                setProducts={setProducts}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

            <div id="dateDiv">
                <h4>
                    Total Items: <span>{orderData?.products.length}</span>
                </h4>
                <h4>
                    Delivery Charge: <span>{orderData?.deliveryCharge}</span>
                </h4>
                {orderData?.offerId && orderData?.offerId !== null ? (
                    <h4>
                        PromoCode Discount:{" "}
                        <span>{orderData?.couponDiscount}</span>
                    </h4>
                ) : null}
                <h4>
                    Total Amount: <span>{orderData?.amount}</span>
                </h4>
            </div>
        </div>
    );
};

export default OrderDetails;
