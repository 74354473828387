import { BrowserRouter as Router } from "react-router-dom";
import MiniDrawer from "./NewComponents/HomePage/HomePage";
import { ToastContainer } from "react-toastify";
import Login from "./NewComponents/Login/Login";

function App() {
    return (
        <Router>
            <div
                style={{
                    backgroundColor: "#F3F9F7",
                    width: "100%",
                    minHeight: "100vh",
                }}
            >
                {/* <LeftPanel /> */}
                {localStorage.getItem("token2") == null ? (
                    <Login />
                ) : (
                    <>
                        <MiniDrawer />
                    </>
                )}
                <ToastContainer stacked />
            </div>
        </Router>
    );
}

export default App;
