import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { formatDate, formatDateIso } from "../../utils/DateHandler";
import "./Routes.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL, headers } from "../../config/config";
import { ReactSortable } from "react-sortablejs";
import BackSvg from "../../assets/back.svg";
import { postDataSec } from "../../Apis/fun";
import { Button, ButtonGroup, TextField } from "@mui/material";
import {
    Add,
    ArrowLeft,
    ArrowLeftOutlined,
    AutoFixHigh,
    ChangeCircleOutlined,
    ChangeCircleTwoTone,
    CreateOutlined,
    DeliveryDiningOutlined,
    DisabledByDefaultOutlined,
    Done,
    EventAvailable,
    Money,
    MoneyOffOutlined,
    PanoramaFishEye,
    PanoramaFishEyeTwoTone,
    RemoveCircleOutline,
    StartOutlined,
    SwitchLeftOutlined,
} from "@mui/icons-material";

const RoutesPage = () => {
    const navigate = useNavigate();
    const [disableButtons, setDisableButtons] = useState(true);

    const [sellerRoutes, setSellerRoutes] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [jobList, SetjobList] = useState([]);
    const [optimisingRoute, setOptimisingRoute] = useState(false);

    const handleBackBtn = () => {
        setSelectedRoute(null);
        setJobs([]);
    };

    const getSellerRoutes = async () => {
        try {
            const result = await axios.get(baseURL + "sellerRoute", headers);
            if (result.data.success) {
                setSellerRoutes(result.data.sellerRoutes);
            } else {
                toast.error(result.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getjobList = async () => {
        try {
            const jobs = await axios.get(
                baseURL + "showAvailableJobs",
                headers
            );
            if (jobs.data.success) {
                SetjobList(jobs.data.jobs);
            } else {
                toast.error("Unable to fetch orders data");
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        let perm = localStorage.getItem("permissions");
        if (perm) {
            perm = JSON.parse(perm);
            if (
                perm.some(
                    (permission) =>
                        (permission.name === "routes" &&
                            permission.read === true) ||
                        permission.name === "admin"
                )
            ) {
                if (
                    perm.some(
                        (permission) =>
                            (permission.name === "routes" &&
                                permission.modify === true) ||
                            permission.name === "admin"
                    )
                )
                    setDisableButtons(false);
                else setDisableButtons(true);
            } else {
                navigate("/404");
            }
        }
        getjobList();
        getSellerRoutes();
    }, []);

    const createSellerRoute = async () => {
        try {
            const result = await axios.post(
                baseURL + "sellerroute",
                {
                    name: selectedRoute.name,
                    jobs: jobs,
                    priority: 1,
                },
                headers
            );
            if (result.data.success) {
                toast.success("Routed Created");
                setSelectedRoute(result.data.sellerRoutes);
                getSellerRoutes();

                getjobList();
            } else {
                toast.error("Error in creating route : ", result.data.message);
            }
        } catch (err) {
            console.log(err);
            toast.error("Error in creating route : ", err);
        }
    };

    const updateSellerRoute = async () => {
        try {
            const data = {
                _id: selectedRoute._id,
                jobs: jobs,
                name: selectedRoute.name,
            };
            const result = await axios.put(
                baseURL + "sellerroute",
                data,
                headers
            );
            if (result.data.success) {
                toast.success("Routed Modified");
                getSellerRoutes();
                getjobList();
            } else {
                toast.error("Error in modifying route : ", result.data.message);
            }
        } catch (err) {
            console.log(err);
            toast.error("Error in modifying route : ", err);
        }
    };

    const updateStatus = async (id, status) => {
        try {
            const result = await postDataSec(
                `${baseURL}sellerroute/updateStatus`,
                {
                    _id: id,
                    status: status,
                }
            );
            if (result.success) {
                toast.success("Updated");
                getSellerRoutes();
            } else {
                toast.error("Unable to Update");
            }
        } catch (err) {
            console.log(err);
            toast.error("Error in updating status : ", err);
        }
    };

    const completeRoute = async (id) => {
        try {
            const result = await axios.post(
                `${baseURL}sellerroute/complete`,
                {
                    routeId: id,
                },
                headers
            );
            if (result.data.success) {
                toast.success(result.data.message);
                getSellerRoutes();
            } else {
                toast.error(result.data.message);
                getSellerRoutes();
            }
            setOptimisingRoute(false);
        } catch (err) {
            console.log(err);
        }
    };
    const startRoute = async (id) => {
        try {
            const result = await axios.post(
                `${baseURL}sellerroute/start`,
                {
                    routeId: id,
                },
                headers
            );
            if (result.data.success) {
                toast.success(result.data.message);
                getSellerRoutes();
            } else {
                toast.error(result.data.message);
                getSellerRoutes();
            }
            setOptimisingRoute(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleCalculatePrice = async () => {
        try {
            setOptimisingRoute(true);
            const result = await axios.post(
                `${baseURL}sellerroute/calculatePrice`,
                {
                    routeId: selectedRoute._id,
                },
                headers
            );
            if (result.data.success) {
                toast.success(result.data.message);
                setSelectedRoute(result.data.route);
                setJobs(result.data.route.jobs);
            } else {
                toast.error(result.data.message);
                getSellerRoutes();
            }
            setOptimisingRoute(false);
        } catch (err) {
            console.log(err);
        }
    };

    const unassignRoute = async (id) => {
        try {
            const result = await postDataSec(
                `${baseURL}sellerroute/unassignRoute`,
                { id }
            );
            if (result.success) {
                toast.success(result.message);
                window.location.reload();
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div
            style={{
                flex: 1,
                display: "flex",
                overflow: "hidden",
                flexDirection: "row",
                gap: 20,
                padding: 20,
            }}
        >
            <div
                style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: "15px",
                    gap: 10,
                    flex: 7,
                    height: "83vh",
                    overflow: "scroll",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px 0px #0000001A",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {selectedRoute !== null ? (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                startIcon={
                                    <ArrowLeftOutlined
                                        fontSize="24"
                                        htmlColor="black"
                                    />
                                }
                                id="backBtn"
                                style={{
                                    background: "yellow",
                                    border: "1px solid yellow",
                                    color: "black",
                                }}
                                onClick={handleBackBtn}
                            >
                                Back
                            </Button>
                            <p>No of orders: {jobs?.length}</p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                marginTop: 20,
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <TextField
                                    placeholder="Name Of route"
                                    variant="outlined"
                                    value={selectedRoute.name}
                                    onChange={(e) => {
                                        setSelectedRoute((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        {!selectedRoute.distance ? (
                            <p>Distance and price not calculated yet</p>
                        ) : (
                            <>
                                <p>
                                    Distance : {selectedRoute.distance / 1000}{" "}
                                    Km
                                </p>
                                <p>Delivery Cost : Rs {selectedRoute.amount}</p>
                                <p>
                                    Duration :{" "}
                                    {(selectedRoute.duration / 60).toFixed(0)}
                                    min
                                </p>
                            </>
                        )}
                        {/* <h3>Orders List</h3> */}
                        {/* <ReactSortable list={jobs} setList={setJobs} swapThreshold={0.1} animation={500}> */}
                        {jobs?.map((job, index) => (
                            <div
                                style={{
                                    backgroundColor: "white",
                                    border: "1px solid #ddd",
                                    marginTop: 10,
                                    padding: 10,
                                    borderRadius: 12,
                                    cursor: "grab",
                                }}
                            >
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <h3>
                                        Order Id :{" "}
                                        <span style={{ fontWeight: 400 }}>
                                            {job?.orderId}
                                        </span>
                                    </h3>
                                    <h3>
                                        Pincode :{" "}
                                        <span style={{ fontWeight: 400 }}>
                                            {job?.pincode}
                                        </span>
                                    </h3>
                                    <Button
                                        startIcon={
                                            <RemoveCircleOutline
                                                color="red"
                                                htmlColor="red"
                                            />
                                        }
                                        disabled={disableButtons}
                                        style={{
                                            borderRadius: 12,
                                            border: "1px solid red",
                                            backgroundColor:
                                                "rgba(255,0,0,0.2)",
                                            height: 40,
                                            padding: "0px 10px",
                                            color: "red",
                                        }}
                                        onClick={() => {
                                            let temp = jobs;
                                            let newTemp = temp.filter(
                                                (item) => item.id !== job.id
                                            );
                                            setJobs(newTemp);
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </div>
                                <p>{job.line1}</p>
                                <p>{job.weight}</p>
                                <p>{job.line2}</p>
                            </div>
                        ))}
                        {/* </ReactSortable> */}
                        <Button
                            startIcon={<CreateOutlined htmlColor="green" />}
                            disabled={disableButtons}
                            style={{
                                backgroundColor: "rgba(0,128,0,0.2)",
                                border: "1px solid green",
                                padding: "10px 20px",
                                borderRadius: 4,
                                marginTop: 20,
                                color: "green",
                            }}
                            onClick={
                                selectedRoute.newRoute
                                    ? createSellerRoute
                                    : updateSellerRoute
                            }
                        >
                            Save route
                        </Button>
                        <Button
                            startIcon={<AutoFixHigh />}
                            style={{
                                padding: "10px 20px",
                                borderRadius: 4,
                                marginTop: 20,
                                marginLeft: 10,
                                opacity: optimisingRoute ? 0.2 : 1,
                            }}
                            variant="contained"
                            disabled={disableButtons || optimisingRoute}
                            onClick={handleCalculatePrice}
                        >
                            Optimise Route
                        </Button>
                        <p>Save Route before using optimise</p>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            gap: 10,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <h2>Routes List</h2>
                            <Button
                                startIcon={<Add htmlColor="black" />}
                                disabled={disableButtons}
                                onClick={() => {
                                    setSelectedRoute({
                                        name: "",
                                        jobs: [],
                                        newRoute: true,
                                    });
                                }}
                                style={{
                                    backgroundColor: "rgb(252,240,10)",
                                    border: "1px solid #e3d400",
                                    color: "black",
                                }}
                            >
                                Add Route
                            </Button>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                gap: 10,
                                maxWidth: "100%",
                                overflowX: "hidden",
                            }}
                        >
                            {sellerRoutes.length > 0 &&
                                sellerRoutes.map((route, index) => (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setSelectedRoute(route);
                                            setJobs(route.jobs);
                                        }}
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            border: "1px solid #ddd",
                                            borderRadius: 16,
                                            padding: 10,
                                            cursor: "pointer",
                                            gap: 20,
                                            alignItems: "center",
                                        }}
                                    >
                                        <h3>{route.id}</h3>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                borderLeft: "1px solid #eee",
                                                paddingLeft: 10,
                                                flex: 1,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: 10,
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    flex: 1,
                                                }}
                                            >
                                                <h3>
                                                    Route Name :{" "}
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        {route.name}
                                                    </span>
                                                </h3>
                                                {route.status === "open" && (
                                                    <>
                                                        <Button
                                                            startIcon={
                                                                <DisabledByDefaultOutlined htmlColor="red" />
                                                            }
                                                            disabled={
                                                                disableButtons
                                                            }
                                                            style={{
                                                                border: "1px solid red",
                                                                backgroundColor:
                                                                    "rgb(252, 232, 232)",
                                                                color: "red",
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                updateStatus(
                                                                    route._id,
                                                                    "inactive"
                                                                );
                                                            }}
                                                        >
                                                            Disable
                                                        </Button>
                                                        <Button
                                                            startIcon={
                                                                <DeliveryDiningOutlined htmlColor="green" />
                                                            }
                                                            disabled={
                                                                disableButtons
                                                            }
                                                            style={{
                                                                border: "1px solid green",
                                                                backgroundColor:
                                                                    "rgb(231, 245, 240)",
                                                                color: "green",
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                startRoute(
                                                                    route._id
                                                                );
                                                            }}
                                                        >
                                                            Start Route
                                                        </Button>
                                                    </>
                                                )}
                                                {(route.status === "Claimed" ||
                                                    route.status ===
                                                        "inTransit") && (
                                                    <Button
                                                        startIcon={
                                                            <DisabledByDefaultOutlined htmlColor="red" />
                                                        }
                                                        disabled={
                                                            disableButtons
                                                        }
                                                        style={{
                                                            border: "1px solid red",
                                                            backgroundColor:
                                                                "rgb(252, 232, 232)",
                                                            color: "red",
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            unassignRoute(
                                                                route._id
                                                            );
                                                        }}
                                                    >
                                                        Unassign
                                                    </Button>
                                                )}
                                                {route.status ===
                                                    "inactive" && (
                                                    <Button
                                                        startIcon={
                                                            <DeliveryDiningOutlined />
                                                        }
                                                        disabled={
                                                            disableButtons
                                                        }
                                                        style={{
                                                            border: "1px solid green",
                                                            backgroundColor:
                                                                "rgb(231, 245, 240)",
                                                            color: "green",
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            updateStatus(
                                                                route._id,
                                                                "open"
                                                            );
                                                        }}
                                                    >
                                                        Enable
                                                    </Button>
                                                )}
                                                {route.status ===
                                                    "inTransit" && (
                                                    <Button
                                                        startIcon={<Done />}
                                                        disabled={
                                                            disableButtons
                                                        }
                                                        style={{
                                                            border: "1px solid green",
                                                            backgroundColor:
                                                                "rgb(231, 245, 240)",
                                                            color: "green",
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            completeRoute(
                                                                route._id
                                                            );
                                                        }}
                                                    >
                                                        Complete
                                                    </Button>
                                                )}
                                            </div>
                                            {/* <Button startIcon={<DisabledByDefaultOutlined htmlColor="red" />} disabled={disableButtons} style={{ border: '1px solid red', backgroundColor: 'rgb(252, 232, 232)', color: 'red' }} onClick={(e) => { e.stopPropagation(); unassignRoute(route._id) }}>Unassign</Button> */}
                                            <h3>
                                                Route Status :{" "}
                                                <span
                                                    style={{ fontWeight: 400 }}
                                                >
                                                    {route.status === "inactive"
                                                        ? "Disabled"
                                                        : route.status ===
                                                          "open"
                                                        ? "Enabled"
                                                        : route.status ===
                                                          "riderReached"
                                                        ? "Arrived for pickup"
                                                        : route.status}
                                                </span>
                                            </h3>
                                            <h3>
                                                Distance :{" "}
                                                <span
                                                    style={{ fontWeight: 400 }}
                                                >
                                                    {route.distance / 1000} km
                                                </span>
                                            </h3>
                                            {route.duration / 60}
                                            <h3>
                                                Duration :{" "}
                                                <span
                                                    style={{ fontWeight: 400 }}
                                                >
                                                    {Math.floor(
                                                        route.duration / 3600
                                                    )}
                                                    hr{" "}
                                                    {Math.floor(
                                                        (route.duration / 60) %
                                                            60
                                                    )}{" "}
                                                    min
                                                </span>
                                            </h3>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    gap: 10,
                                                    maxWidth: "100%",
                                                    overflowX: "hidden",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                {route?.jobs?.map((job) => (
                                                    <div
                                                        style={{
                                                            borderRadius: 12,
                                                            border:
                                                                job?.status ===
                                                                "complete"
                                                                    ? "1px solid green"
                                                                    : "1px solid #ddd",
                                                            padding: 20,
                                                            marginBottom: 5,
                                                            minWidth: 100,
                                                        }}
                                                    >
                                                        <p>
                                                            Order Id :{" "}
                                                            {job?.orderId}
                                                        </p>
                                                        <p>
                                                            Pincode :{" "}
                                                            {job?.pincode}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
            </div>
            <AvailableOrdersList
                jobList={jobList}
                selectedRoute={selectedRoute}
                jobs={jobs}
                setJobs={setJobs}
                disableButtons={disableButtons}
                navigate={navigate}
            />
        </div>
    );
};

const AvailableOrdersList = ({
    jobList,
    selectedRoute,
    jobs,
    setJobs,
    disableButtons,
    navigate,
}) => {
    const [searchJobId, setSearchJobId] = useState("");
    const [filteredJobs, setFilteredJobs] = useState(null);

    useEffect(() => {
        const temp = jobList?.filter((item) =>
            String(item.order[0].id).includes(searchJobId)
        );
        setFilteredJobs(temp);
    }, [searchJobId, jobList]);

    const changeType = async (id) => {
        try {
            const result = await postDataSec(`${baseURL}jobs/changeType`, {
                id: id,
            });
            if (result.success) {
                toast.success(result.message);
                window.location.reload();
            } else {
                toast.error(toast.error);
            }
        } catch (err) {
            console.log(err);
            toast.error("Error in updating status : ", err);
        }
    };

    return (
        <div
            style={{
                backgroundColor: "rgb(255, 255, 255)",
                padding: "15px",
                gap: 10,
                flex: 3,
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px 0px #0000001A",
                display: "flex",
                flexDirection: "column",
                height: "83vh",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    Orders ({jobList?.length})
                </span>
            </div>
            <input
                style={{ padding: 12, margin: 0, display: "flex" }}
                value={searchJobId}
                // autoFocus
                onChange={(e) => {
                    setSearchJobId(e.target.value);
                }}
                type="text"
                placeholder="Search order"
            />
            <div
                style={{
                    overflowX: "hidden",
                    overflowY: "scroll",
                    height: "70vh",
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                }}
            >
                {filteredJobs &&
                    filteredJobs.map((job, index) => (
                        <div
                            style={{
                                opacity: jobs.find(
                                    (orderitem) => orderitem.id === job.id
                                )
                                    ? 0.3
                                    : 1,
                                cursor: "pointer",
                                // width: '100%',
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 10,
                                gap: 5,
                                // height: 180,
                                borderRadius: 12,
                                // maxHeight: 200,
                                // minWidth: 500,
                                border: "1px solid #ddd",
                            }}
                            onClick={() => {
                                if (
                                    jobs.find(
                                        (orderitem) => orderitem.id === job.id
                                    )
                                ) {
                                    return toast.error("Already added");
                                }
                                if (selectedRoute) {
                                    setJobs((prev) => [
                                        ...prev,
                                        {
                                            jobId: job._id,
                                            id: job.id,
                                            orderId: job.order[0].id,
                                            pincode:
                                                job.order[0].address
                                                    .fullAddress,
                                            line1: job.order[0].address.line1,
                                            line2: job.order[0].address.line2,
                                            latitude:
                                                job.order[0].address.latitude,
                                            longitude:
                                                job.order[0].address.longitude,
                                        },
                                    ]);
                                } else {
                                    toast.error("Select a route to add order");
                                }
                            }}
                            key={job._id}
                        >
                            <div
                                style={{
                                    display: "grid",

                                    gridTemplateColumns: "repeat(2, 1fr)",
                                    gap: 5,
                                    marginBottom: 0,
                                }}
                            >
                                <p style={{ fontWeight: 700 }}>
                                    {" "}
                                    Order Id : {job.order[0].id}
                                </p>
                                <p>
                                    {" "}
                                    Pincode :{job.order[0].address.fullAddress}
                                </p>
                                <p> Amount : Rs {job.order[0].amount}</p>
                                <p>{job.order[0].paid ? "" : "Not"} Paid </p>
                            </div>
                            <p>
                                {" "}
                                Date of delivery:
                                <span style={{ fontWeight: 700 }}>
                                    {" "}
                                    {formatDate(job.order[0].deliveryDate)}
                                </span>
                            </p>
                            <p style={{ fontWeight: 700, marginTop: 30 }}>
                                Address :
                            </p>
                            <p>{job.order[0].address.line1}</p>
                            <p>{job.order[0].address.line2}</p>
                            <div style={{ display: "flex", flex: 1, gap: 5 }}>
                                <ButtonGroup>
                                    <Button
                                        disabled={disableButtons}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(
                                                `/orderdetails?id=${job.order[0].id}`
                                            );
                                        }}
                                        startIcon={<PanoramaFishEyeTwoTone />}
                                    >
                                        View
                                    </Button>
                                    <Button
                                        disabled={disableButtons}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            changeType(job._id);
                                        }}
                                        startIcon={<ChangeCircleOutlined />}
                                    >
                                        Convert to Single Delivery
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default RoutesPage;
