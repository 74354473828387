import { useEffect, useState } from "react";
import { getDataSec } from "../../Apis/fun";
import { baseURL } from "../../config/config";
import { Button, Table, TableCell, TableHead, TableRow } from "@mui/material";
import { formatDate, formatDateIso } from "../../utils/DateHandler";

//show all the transactions of the wallet
const WalletTransactions = ({}) => {
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const limit = 40;

    const getData = async () => {
        try {
            const result = await getDataSec(
                `${baseURL}wallet/transactions?page=${page}&limit=${limit}`
            );
            if (result.success) {
                console.log(result.transactions);
                setTransactions((prev) => [...prev, ...result.transactions]);
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getData();
    }, [page]);

    return (
        <div
            style={{
                margin: 20,
                padding: 20,
                backgroundColor: "white",
                borderRadius: 20,
            }}
        >
            <h1>Wallet Transactions</h1>
            <Table style={{ backgroundColor: "white", borderRadius: 20 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Transaction ID</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Transaction Type</TableCell>
                        <TableCell>Transaction Date</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                    {transactions.length &&
                        transactions.map((transaction, index) => (
                            <TableRow
                                key={index}
                                style={{
                                    backgroundColor:
                                        transaction.type === "debit"
                                            ? "rgba(255,220,220,0.7)"
                                            : "rgba(220,255,220,0.7)",
                                }}
                            >
                                <TableCell>{transaction.id}</TableCell>
                                <TableCell>
                                    {transaction.type === "debit"
                                        ? -transaction.amount
                                        : transaction.amount}
                                </TableCell>
                                <TableCell>{transaction.type}</TableCell>
                                <TableCell>
                                    {formatDateIso(transaction.date)}
                                </TableCell>
                                <TableCell>{transaction.message}</TableCell>
                                <TableCell
                                    style={{
                                        flexDirection: "column",
                                        display: "flex",
                                        gap: 5,
                                    }}
                                >
                                    {transaction.orderId && (
                                        <Button
                                            style={{
                                                borderColor: "black",
                                                color: "black",
                                            }}
                                            onClick={() => {
                                                window.location.href = `/orderdetails?id=${transaction.orderId}`;
                                            }}
                                            variant="outlined"
                                        >
                                            View order
                                        </Button>
                                    )}
                                    {transaction.userId && (
                                        <Button
                                            style={{
                                                borderColor: "black",
                                                color: "black",
                                            }}
                                            onClick={() => {
                                                window.location.href = `/customerdetails?id=${transaction.userId}`;
                                            }}
                                            variant="outlined"
                                        >
                                            View Customer
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableHead>
            </Table>
            <Button
                onClick={() => {
                    setPage((prev) => prev + 1);
                }}
            >
                Show More
            </Button>
        </div>
    );
};

export default WalletTransactions;
